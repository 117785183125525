import type { FiltersGroup, FiltersValue } from '@Types/Filters';

/** returns applied filters names without duplicates */
function getFiltersNames(appliedFilters: FiltersValue[], allFilters: FiltersGroup[]) {
  const appliedParameterIds = appliedFilters.map((filter) => filter.parameterId);

  const appliedFilterNames = allFilters
    .filter((filter) => filter.items.some((item) => appliedParameterIds.includes(item.parameterId)))
    .map((filter) => filter.name);

  return Array.from(new Set(appliedFilterNames));
}

export default getFiltersNames;
