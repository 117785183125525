export type Id = string;

export interface Instance<Stores> {
  id: Id;
  stores: Stores;
}

export const Cache = (() => {
  const cache = {};

  function createEntity<Stores>(id: Id, creator): Instance<Stores> {
    const stores = creator();

    cache[id] = {
      id,
      stores,
    };

    return cache[id];
  }

  function getEntity<Stores>(id: Id): Instance<Stores> {
    return cache[id];
  }

  function clearEntity<Stores>(id: Id): Instance<Stores> {
    cache[id] = null;

    return cache[id];
  }

  return { createEntity, getEntity, clearEntity };
})();
